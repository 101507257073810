import logo from './logo.svg';
import './App.css';


const weatherOptions = [
  "Niks aan de hand, lekker weer",
  "Zwembroek aan en gaan!",
  "Man, man, flink onweer",
  "Code zwart",
  "Lichte kans op vanalles",
  "Hier en daar een bui",
  "Fikse wind",
  "Het waait wel maar de kust is droog",
  "Hagel, hagel, hagel",
  "Voelt als lente",
  "Terrasweer ten top!",
  "Half uurtje nat",
  "Code bruin",
  "Code beige",
  "In de Bilt is het in ieder geval lekker",
  "Typisch Novemberweer",
  "Windstoten",
  "Regenstoten",
  "Tja",
  "Voelt als Madrid",
  "Fietsweer ten top",
  "Binnenzitweer",
  "Perfect voor kriek",
  "Vodkaweer",
  "We weten het even niet zo goed",
  "Ideaal weer om te curlen",
  "Het blijft voorlopig zo",
  "Heel de week dit weer",
  "Hangende wolken bijten niet",
  "Gezellig bewolkt",
  "Spaans benauwd",
  "Windstil",
  "Steek de BBQ maar aan",
  "Heel gek weer",
  "Onvoorspelbaar hoor, vandaag",
  "De weerman is op vakantie, sorry",
  "Kleine kans op gehaktballen",
  "Het weer is top maar het ruikt gek buiten",
  "Gewoon hondenweer",
  "Typisch Nederlands",
  "Onverwachts lekker buiten",
  "Wollensokkenweer",
  "Nylonsokkenweer",
  "Lerensokkenweer"
]


const minMaxTemp = [-22, 45]

const getRandomTemp = (minMax) => {
  const [min, max] = minMax;
  const temperature = Math.floor(Math.random() * (max - min + 1) + min);
  const fractions = ["½", "⅜", "⅕", "¹⁷⁄₂₁", "⁴⁄₄₅", "⁹⁹⁄₁₀₀"];
  
  // Randomly decide to add a fraction or not (e.g., 20% chance to add)
  if (Math.random() < 0.1) {
    const randomFraction = fractions[Math.floor(Math.random() * fractions.length)];
    return `${temperature}${randomFraction}`;
  }
  
  return temperature;
};


const getRandomWeather = (options) => {
  const index = Math.floor(Math.random() * options.length);
  return options[index];
}

function App() {

  const temp = getRandomTemp(minMaxTemp)
  const weather = getRandomWeather(weatherOptions)

  return (
    <div className="App">
      <h3>Het weer de komende uren:</h3>
      <h1>{weather}</h1>
      <h4>Rond de {temp}&deg; C</h4>
      <hr/>
      <span>Copyright buienleugens.nl aan ons advies kunnen geen rechten worden ontleend.</span>
    </div>
  );
}

export default App;
